<template>
    <admin-dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="intervals"
            :items-per-page="20"
            class="elevation-1"
        >
            <template v-slot:item.email="{ item }">
                {{ item.patient.user.name }}<br /><i class="blur-email">{{ item.patient.user.email }}</i>
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
    import Interval from '../../models/Interval';
    import AdminDashboardLayout from "../../layouts/AdminDashboardLayout";

    export default {
        name: "admin-intervals",
        components: {AdminDashboardLayout},
        data: function () {
            return {
                loading: false,
                intervals: [],
                headers: [
                    { text: 'Email', value: 'email' },
                    { text: 'Note', value: 'note' },
                    { text: 'Started at', value: 'started_at' },
                    { text: 'Ended at', value: 'ended_at' }
                ],
            }
        },
        async mounted() {
            this.loading = true
            this.intervals = await Interval.get()
            this.loading = false
        },
    }
</script>

<style scoped>
.blur-email { color: grey; }
</style>
